import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import axios from "@axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { formatFilters as buildMetadataFilters } from "@/components/MetaFiltersComponent/helpers"
import { debounce } from "lodash"
import useFilterCounter from "@/components/Filters/useFiltersCount";

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null);
  const toast = useToast();
  const tableColumns = [
    { label: "detection_event.media", key: "media", sortable: false },
    { label: "camera.title", key: "camera_proxy", sortable: true },
    { label: "detection_event.event_type", key: "formatted_event_type", sortable: true },
    {
      label: "detection_event.event_created_at",
      key: "event_created_at",
      sortable: true,
      formatter: (value, key, item) => {
        return moment(value).format("DD/MM/YYYY HH:mm:ss");
      },
    },
    { label: "ID", key: "id", sortable: true },
    { label: "detection_event.data", key: "data", sortable: true },
    { label: "alarm.title", key: "id_alarm", sortable: true },
    {
      label: "date.created_at",
      key: "created_at",
      sortable: true,
      formatter: (value, key, item) => {
        return moment(value).format("DD/MM/YYYY HH:mm:ss");
      },
    },
  ];

  const user = JSON.parse(window.localStorage.userData);

  if (user.is_master_admin) {
    tableColumns.splice(2, 0, { label: "detection_event.provider", key: "provider", sortable: true },)
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const alarmFilter = ref(null);
  const action = ref("list");

  const unitFilter = ref(null);
  const unitOptions = ref([]);
  const cameraFilter = ref(null);
  const cameraOptions = ref([]);

  const eventTypeFilter = ref(null);
  const eventTypeOptions = ref([]);
  const providerFilter = ref(null);
  const providerOptions = ref([]);

  const startDatetimeFilter = ref(null)
  const endDatetimeFilter = ref(null)

  const filters = ref({});
  const filtersCount = useFilterCounter(filters);

  // Metadata
  const objectTypeData = ref({});

  store.dispatch('tenant/getAll')
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  axios('v1/unit?hasDetectionEvent=true').then((response) => {
    unitOptions.value = response.data.data.map((unit) => ({
      label: unit.description,
      value: unit.id,
    }));
  });

  axios('v1/proxy_camera?hasDetectionEvent=true&max=5000').then((response) => {
    cameraOptions.value = response.data.data.map((camera) => ({
      label: camera.camera.name,
      value: camera.id,
    }));
  });

  axios('v1/services/detection-events/get-catalog').then((response) => {
    providerOptions.value = response.data.data.providers.map((provider) => ({
      label: provider.label,
      value: provider.value,
    }));

    response.data.data.providers.forEach(provider => {
      provider.detections.forEach((event_type) => {

        if (!eventTypeOptions.value.map(value => (value.value)).includes(event_type.value)) {
          eventTypeOptions.value.push({
            label: event_type.label,
            value: event_type.value,
          })
        }
      })
    })
  });

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {

    switch (sortBy.value) {
      case 'camera_proxy':
        sortBy.value = 'id_camera_proxy'
        break;
      case 'formatted_event_type':
        sortBy.value = 'event_type';
        break;
      case '':
        sortBy.value = 'id';
        break;
    }

    store
      .dispatch("detection_event/getAll", {
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        action: action.value,
        q: searchQuery.value,
        ...buildFilters(),
      })
      .then((response) => {
        if (action.value == "export") {
          action.value = "list";
          loadingToast.value.show(
            trans["actions.download"],
            trans["messages.export.download_succesfully"],
            "secondary"
          );

          function id() {
            return store.getters["country/getIdNotification"];
          }
          function url() {
            return store.getters["country/getUrl"];
          }

          const notification = {
            id: id(),
            progress: 100,
            completed: true,
            view: "countries",
            url: url(),
            download: id(),
            title: `${trans["report_of"]} ${trans["country.title"]}`,
            body: trans["full_download"],
            error: false,
            timestamp:
              new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
          };
          store.dispatch("grid/updateDownloadNotification", notification);
        } else {
          callback(response.data);
          totalList.value = response.meta.total;
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const buildFilters = () => {
    filters.value = {
      alarm: alarmFilter.value,
      unit: unitFilter.value,
      tenant: tenantFilter.value,
      cameras: cameraFilter.value,
      provider: providerFilter.value,
      event_type: eventTypeFilter.value,
      from: startDatetimeFilter.value,
      to: endDatetimeFilter.value,
      ...buildMetadataFilters(objectTypeData.value),
    };
    return filters.value;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  const clearSearchFilters = () => {
    searchQuery.value = null;
    alarmFilter.value = null;
    unitFilter.value = null;
    tenantFilter.value = null;
    cameraFilter.value = null;
    providerFilter.value = null;
    eventTypeFilter.value = null;
    startDatetimeFilter.value = null;
    endDatetimeFilter.value = null;
    objectTypeData.value = {};
    filters.value = {};
    refetchData();
  };

  watch([
    currentPage,
    perPage,
  ], () => {
    refetchData();
  });

  const debouncedRefetchData = debounce(refetchData, 500);
  watch([
    searchQuery,
  ], () => {
    debouncedRefetchData();
  });

  const applyChartFilters = (filters) => {
    if (filters) {
      tenantFilter.value = filters['tenant'] || null;
      cameraFilter.value = filters['camera'] || null;
      unitFilter.value = filters['unit'] || null;
      providerFilter.value = filters['provider'] || null;
      eventTypeFilter.value = filters['event_type'] || null;

      if (filters['from'] || filters['to']) {
        startDatetimeFilter.value = filters['from'];
        endDatetimeFilter.value = filters['to'];
      }
    }
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    alarmFilter,
    refetchData,
    clearSearchFilters,
    action,
    applyChartFilters,

    unitFilter,
    unitOptions,
    cameraFilter,
    cameraOptions,
    tenantOptions,
    tenantFilter,
    startDatetimeFilter,
    endDatetimeFilter,
    eventTypeFilter,
    eventTypeOptions,
    providerFilter,
    providerOptions,
    objectTypeData,
    filtersCount,
    filters,
    buildFilters,
  };
}
