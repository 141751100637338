<template>
  <div class="d-flex w-100 mb-1">
    <b-form-select
      v-model="selectedOperator"
      :options="operators"
      class="mr-1"
      :style="`width: ${fieldWidth};`"
    ></b-form-select>
    <v-select
      v-if="list && !loadingList"
      v-model="model"
      :options="list"
      :multiple="['is_in', 'is_not_in'].includes(selectedOperator)"
      :append-to-body="true"
      :calculate-position="withPopper"
      :disabled="loadingList"
      :style="`width: ${fieldWidth};`"
    />
    <v-select
      v-else-if="!list && ['is_in', 'is_not_in'].includes(selectedOperator)"
      v-model="model"
      taggable
      multiple
      :append-to-body="true"
      :calculate-position="withPopper"
      :disabled="loadingList"
      :style="`width: ${fieldWidth};`"
    />
    <div v-else-if="loadingList" class="text-center" :style="`width: ${fieldWidth};`">
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>
    <input
      v-else
      v-model="model"
      type="text"
      class="form-control"
      :style="`width: ${fieldWidth};`"
    />
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { BSpinner } from "bootstrap-vue";
import { createPopper } from "@popperjs/core";
import { toQueryString } from './helpers';

const eventMsUrl = `${process.env.VUE_APP_MS_EVENTS_URL}/field-values`;

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    type: String,
    value: Object,
    config: Object,
    extFilters: Object,
  },
  mounted() {
    if (this.isObject(this.value)) {
      this.model = this.value.value;
      this.selectedOperator = this.value.operator;
    } else {
      this.model = this.value;
    }

    if (this.config && this.config.list) {
      this.hasList = true;
      this.setList();
    } else {
      this.loadingList = false;
    }
  },
  data() {
    return {
      model: null,
      selectedOperator: "contains",
      fieldWidth: "260px",
      operators: [
        {
          value: "equal_to",
          text: this.$t("grid.timeline.objectsModal.operators.equal_to"),
        },
        {
          value: "not_equal_to",
          text: this.$t("grid.timeline.objectsModal.operators.not_equal_to"),
        },
        {
          value: "contains",
          text: this.$t("grid.timeline.objectsModal.operators.contains"),
        },
        {
          value: "not_contains",
          text: this.$t("grid.timeline.objectsModal.operators.not_contains"),
        },
        { value: "gt", text: this.$t("grid.timeline.objectsModal.operators.gt") },
        { value: "lt", text: this.$t("grid.timeline.objectsModal.operators.lt") },
        { value: "gte", text: this.$t("grid.timeline.objectsModal.operators.gte") },
        { value: "lte", text: this.$t("grid.timeline.objectsModal.operators.lte") },
        { value: "is_in", text: this.$t("grid.timeline.objectsModal.operators.is_in") },
        {
          value: "is_not_in",
          text: this.$t("grid.timeline.objectsModal.operators.is_not_in"),
        },
      ],
      hasList: false,
      list: null,
      loadingList: true,
    };
  },
  watch: {
    model(data) {
      if (data && (Array.isArray(data) ? data.length > 0 : true)) {
        this.$emit("input", { value: data, operator: this.selectedOperator });
      } else {
        this.$emit("input", null);
      }
    },
    selectedOperator(operator) {
      this.$emit("input", {
        value: this.model || null,
        operator,
      });
    },
  },
  methods: {
    isObject(value) {
      return typeof value === "object" && value !== null && !Array.isArray(value);
    },
    setList() {
      if (this.config.list.constructor === Array) {
        this.list = this.config.list;
        this.loadingList = false;
      } else if (this.config.list.constructor === String) {
        this.fetchList();
      }
    },
    fetchList() {
      let url = `${eventMsUrl}?${this.config.list}`;

      if (this.extFilters.customFields) {
        url += `&${toQueryString(this.extFilters.customFields)}`;
      }

      axios.get(url).then(({ data }) => {
        const resp = data.data;
        if (resp && resp.length > 0) {
          this.list = resp.map((item) => ({ value: item, label: item }));
        }
        this.loadingList = false;
      });
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      dropdownList.style.zIndex = (500000).toString();
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
      });
      return () => popper.destroy();
    },
  },
};
</script>

<style></style>
