<template>
  <div class="ot-container">
    <b-row align-v="center" class="d-flex align-items-center mr-1">
      <b-col cols="6" md="6" class="mb-2" v-if="userData.is_master_admin">
        <label class="text-capitalize">{{ $tc("detection_event.provider") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="providerFilter"
          :options="providerOptions"
          class="w-100"
          multiple
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:providerFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
      <b-col
        :cols="colsSize"
        :md="colsSize"
        class="mb-2"
      >
        <label class="text-capitalize">{{ $tc("detection_event.event_type") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="eventTypeFilter"
          :options="eventTypeOptions"
          class="w-100"
          multiple
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:eventTypeFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
      <b-col
        :cols="colsSize"
        :md="colsSize"
        class="mb-2"
      >
        <label class="text-capitalize">{{ $tc("unit.title", 2) }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="unitFilter"
          :options="unitOptions"
          class="w-100"
          multiple
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:unitFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
      <b-col
        :cols="colsSize"
        :md="colsSize"
        class="mb-2"
      >
        <label class="text-capitalize">{{ $tc("camera.title", 2) }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="cameraFilter"
          :options="cameraOptions"
          class="w-100"
          multiple
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:cameraFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
      <b-col cols="6" md="6" class="mb-2 text-capitalize">
        <label class="text-capitalize">{{ $tc("tenants.title", 1) }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="tenantFilter"
          :options="tenantOptions"
          class="w-100"
          multiple
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:tenantFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
      <b-col cols="6" md="6" class="mb-2">
        <label class="text-capitalize">{{ $tc("alarm.title", 1) }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="alarmFilter"
          class="w-100"
          :reduce="(val) => val.value"
          :options="[
            { label: 'true', value: 1 },
            { label: 'false', value: 0 },
          ]"
          @input="(val) => $emit('update:alarmFilter', val)"
        />
      </b-col>
      <b-col cols="6" md="6" class="mb-2">
        <b-form-group class="mb-0">
          <label class="text-capitalize">Start Datetime</label>
          <flat-pickr
            class="form-control"
            :config="{ maxDate: new Date(), enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement: 1 }"
            :value="startDatetime"
            @on-change="(selectedDates, val) => $emit('update:startDatetime', val)"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6" md="6" class="mb-2">
        <b-form-group class="mb-0">
          <label class="text-capitalize">End Datetime</label>
          <flat-pickr
            class="form-control"
            :config="{ maxDate: new Date(), enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement: 1 }"
            :value="endDatetime"
            @on-change="(selectedDates, val) => $emit('update:endDatetime', val)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { integer } from "@/@core/utils/validations/validations";
import flatPickr from "vue-flatpickr-component";
import popperHelper from "@/libs/popper-helper";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    alarmFilter: {
      type: integer,
      default: null,
    },
    unitFilter: {
      type: Array,
      default: null,
    },
    unitOptions: {
      type: Array,
      default: null,
    },
    cameraFilter: {
      type: Array,
      default: null,
    },
    cameraOptions: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    startDatetime: {
      type: String,
      default: null,
    },
    endDatetime: {
      type: String,
      default: null,
    },
    eventTypeOptions: {
      type: Array,
      default: null,
    },
    eventTypeFilter: {
      type: Array,
      default: null,
    },
    providerOptions: {
      type: Array,
      default: null,
    },
    providerFilter: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      userData: JSON.parse(window.localStorage.userData),
    };
  },
  computed: {
    colsSize() {
      return this.userData.is_master_admin ? '6' : '6';
    }
  },
  setup() {
    return {
      withPopper: popperHelper.withPopper,
    };
  }
};
</script>

<style lang="scss" scoped>
.ot-container {
  min-height: 280px;
  max-height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
