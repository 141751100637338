<template>
  <div>
    <toastification-progress-content ref="progressToast" />
    <b-row v-if="showFilters">
      <b-col cols="6">
        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />
      </b-col>
      <b-col cols="6" class="pr-3 my-2 text-right">
        <div class="d-flex justify-content-end">
          <div>
            <b-button-group>
              <b-button
                @click="$root.$emit('bv::show::modal', 'metadata_search_modal')"
                class="buttonFilter cursor-pointer"
              >
                <b-icon-filter-circle-fill scale="1" />
                <span class="align-middle ml-50">Filters</span>
              </b-button>
              <toggle-clear-filter
                @clear-search-filters="clearSearchFilters"
                :filters-count="filtersCount"
              />
            </b-button-group>
          </div>
        </div>
      </b-col>
    </b-row>

    <toastification-loading-content ref="loadingToast" />

    <!-- Table Container Card -->
    <b-card-group deck :class="{ 'mx-2': showFilters, 'm-0': !showFilters }">
      <b-card no-body class="m-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="text-capitalize">{{ $t("table.show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="text-capitalize">{{ $t("table.entries") }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('table.search')"
                />
                <b-button
                  @click="exportExcel()"
                  variant="success"
                  class="text-capitalize"
                  type="button"
                  :disabled="buttonExport"
                  v-b-tooltip.hover
                  :title="$t('messages.export.title')"
                >
                  <feather-icon icon="DownloadCloudIcon" size="15" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('no_record_found')"
          :sort-desc.sync="isSortDirDesc"
          @row-selected="onRowSelected"
          select-mode="single"
          selectable
        >
          <template #head()="data">
            <span>
              {{ !["ID"].includes(data.label) ? $tc(data.label, 1) : data.label }}</span
            >
          </template>

          <template #cell(media)="data">
            <div class="hoverable">
              <b-img
                v-if="data.item.event_type == 'fr'"
                :src="pathImage + data.item.data.image"
                alt="path Image"
                style="max-height: 150px; width: fit-content; max-width: 500px"
                @click="openImageModal(data.item.media.url)"
                v-b-modal.image_modal
              ></b-img>
              <b-img
                v-else-if="data.item.media"
                :src="data.item.media ? data.item.media.url : ''"
                alt="path Image"
                style="max-height: 150px; width: fit-content; max-width: 500px"
                @click="openImageModal(data.item.media.url)"
                v-b-modal.image_modal
              ></b-img>
            </div>
          </template>

          <template #cell(camera_proxy)="data">
            <b-link style="padding: 50% 0px 50% 0" @click="showVideo(data.item)">
              {{ data.item.camera_proxy.camera.name }}
            </b-link>
          </template>

          <template #cell(data)="data">
            <pre
              style="max-height: 150px"
              v-if="data.item.data && data.item.event_type !== 'fr'"
              >{{ JSON.stringify(data.item.data, null, "\t") }}</pre
            >
            <span v-else>
              {{ $t('facial_recognition.age') }}
              {{ data.item.data.age }}
            </span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <Pagination
                :totalList="totalList"
                :currentPage="currentPage"
                @updatePagination="update"
                :perPage="perPage"
              >
              </Pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <!-- Modal -->
      <b-card class="ml-2 mr-0 sticky-card" v-if="selectedRow" no-body>
        <div class="ml-auto float-rigth">
          <feather-icon
            icon="XIcon"
            class="mr-2 ml-auto mt-2 float-rigth"
            style="cursor: pointer"
            size="30"
            @click="selectedRow = null"
          />
        </div>
        <div class="mx-2" v-if="detectionEvent">
          <lpr-detection
            v-if="detectionEvent.event_type == 'lpr'"
            :detectionEvent="detectionEvent"
          />

          <fr-data
            v-if="detectionEvent.event_type == 'fr'"
            :key="`fr-data-${detectionEvent.id}`"
            :dataMatch="detectionEvent.luna_match"
            :detectionEventData="detectionEvent"
            :frData="detectionEvent.data"
            :index="detectionEvent.id"
            :isLastOne="false"
          />

          <generic-detection
            v-if="detectionEvent.event_type != 'lpr' && detectionEvent.event_type != 'fr'"
            :detectionEvent="detectionEvent"
          />
        </div>
      </b-card>
    </b-card-group>

    <div class="float-video-container" v-if="selectedObject" style="z-index: 100">
      <feather-icon
        icon="XIcon"
        class="float-video-container_close-btn"
        style="cursor: pointer"
        size="20"
        @click="closeDataModal"
      />
      <div class="mx-2">
        <!-- Info -->
        <div class="detection-evt-video-container">
          <p>
            ID: {{ selectedObject.event_data.id }} |
            {{ selectedObject.event_data.formatted_event_type }} -
            {{ selectedObject.event_data.event_created_at }}
          </p>
          <cam-slot
            v-if="selectedObject.camera_data"
            :data="selectedObject.camera_data"
            :type="selectedObject.type"
          />
        </div>
      </div>
    </div>
    <metadata-search-modal @search="refetchData()" @clear="clearSearchFilters()">
      <!-- Filters -->
      <template v-slot:main-filters>
        <filters
          :unit-filter.sync="unitFilter"
          :unit-options="unitOptions"
          :tenant-filter.sync="tenantFilter"
          :tenant-options="tenantOptions"
          :camera-filter.sync="cameraFilter"
          :camera-options="cameraOptions"
          :event-type-filter.sync="eventTypeFilter"
          :event-type-options="eventTypeOptions"
          :provider-filter.sync="providerFilter"
          :provider-options="providerOptions"
          :start-datetime.sync="startDatetimeFilter"
          :end-datetime.sync="endDatetimeFilter"
          :alarm-filter.sync="alarmFilter"
        />
      </template>
      <template v-slot:meta-filters>
        <meta-filters-component v-model="objectTypeData" />
      </template>
    </metadata-search-modal>
    <image-modal />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useList from "./useList";
import Filters from "./Filters.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ToastificationProgressContent from "@core/components/toastification/ToastificationProgressContent.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import Pagination from "@/components/Pagination.vue";
import ImageModal from "../components/GridView/widgets/ImageModal.vue";
import MetadataSearchModal from "@/components/MetadataSearchModal.vue";
import MetaFiltersComponent from "@/components/MetaFiltersComponent";
import store from "@/store";
import CamSlot from "../components/GridView/layouts/slots/CamSlot.vue";
import { SLOT_TYPES } from "@/config/layoutConfig";
import { ref, computed, watch, nextTick } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent from "@/@core/components/toastification/ToastificationLoadingContent.vue";
import FrData from "@/views/components/GridView/layouts/slots/InfoSlot/FRMatches/FRData.vue";
import LprDetection from "@/views/components/DetectionEventSlot/LprDetection.vue";
import GenericDetection from "@/views/components/DetectionEventSlot/GenericDetection.vue";
import ToggleClearFilter from "@/components/Filters/ToggleClearFilter.vue";

export default {
  components: {
    Filters,
    Breadcrumb,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationProgressContent,
    Pagination,
    ImageModal,
    CamSlot,
    MetadataSearchModal,
    MetaFiltersComponent,
    ToastificationLoadingContent,
    LprDetection,
    FrData,
    GenericDetection,
    ToggleClearFilter,
  },
  props: {
    filters: {
      type: Object | Array,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination
    const selectedRow = ref(null);
    const detectionEvent = ref(null);
    const selectedObject = ref(null);
    const { t } = useI18nUtils();
    const buttonExport = ref(false);
    const progressToast = ref(null);
    const progress = computed(() => store.getters["detection_event/getProgress"]);
    const error = computed(() => store.getters["detection_event/getError"]);
    const url = computed(() => store.getters["detection_event/getUrl"]);

    const toast = useToast();

    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };

    const openImageModal = async (imageUrl) => {
      await store.dispatch("image_modal/imageUrl", imageUrl);
    };

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      clearSearchFilters,
      alarmFilter,
      unitFilter,
      unitOptions,
      cameraFilter,
      cameraOptions,
      tenantOptions,
      tenantFilter,
      eventTypeFilter,
      eventTypeOptions,
      providerFilter,
      providerOptions,
      startDatetimeFilter,
      endDatetimeFilter,
      objectTypeData,
      applyChartFilters,
      filtersCount,
      buildFilters,
    } = useList();

    const onRowSelected = (item) => {
      if (!props.showFilters) return;

      selectedRow.value = item[0];
      if (item.length > 0) {
        detectionEvent.value = item[0];
      }
    };

    const closeDataModal = () => {
      selectedObject.value = null;
    };

    const showVideo = (item) => {
      selectedObject.value = null;
      const selectedItem = item;
      if (selectedItem && selectedItem.camera) {
        nextTick(() => {
          selectedObject.value = {
            event_data: selectedItem,
            camera_data: {
              camera: selectedItem.camera,
              ts: selectedItem.event_created_at,
            },
            type: SLOT_TYPES.CAM_PA,
          };
        });
      }
    };

    applyChartFilters(props.filters);

    // Export =============================

    store.dispatch("grid/findDownloadNotification", "detection-event").then((data) => {
      if (data.length > 0) {
        buttonExport.value = true;
        progressToast.value.show("Descarga", "Generando archivo...", "secondary");
      }
    });

    const exportExcel = () => {
      buttonExport.value = true;
      store.dispatch("detection_event/resetProgress");
      progressToast.value.show("Descarga", "Generando archivo...", "secondary");

      store
        .dispatch("detection_event/getAll", {
          ...buildFilters(),
          action: "export",
        })
        .then((response) => {
          store.dispatch("detection_event/setExport", response.data.jobId);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error exportando la información",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          buttonExport.value = false;
          progressToast.value.close();
        });
    };

    watch([progress], () => {
      progressToast.value.updateProgress(progress.value);
    });

    watch([error], () => {
      toast({
        component: ToastificationContent,
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      buttonExport.value = false;
      progressToast.value.close();
    });

    watch([url], () => {
      if (url.value) {
        const link = document.createElement("a");
        link.href = url.value;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        buttonExport.value = false;
        progressToast.value.close();
      }
    });

    const loadingToast = ref(null);
    const action = ref("list");
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.analytics.detections_events");

    const pathImage = ref(process.env.VUE_APP_BASE_URL + '/storage');

    return {
      buttonExport,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      clearSearchFilters,
      alarmFilter,
      loadingToast,
      action,
      update,

      unitOptions,
      unitFilter,
      cameraFilter,
      cameraOptions,
      tenantOptions,
      tenantFilter,
      eventTypeFilter,
      eventTypeOptions,
      providerFilter,
      providerOptions,

      startDatetimeFilter,
      endDatetimeFilter,
      objectTypeData,

      openImageModal,
      closeDataModal,

      selectedObject,
      filtersCount,
      onRowSelected,
      exportExcel,
      progressToast,
      selectedRow,
      detectionEvent,
      showVideo,
      pathImage
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.sticky-card {
  position: sticky;
  top: 6rem;
  height: 80vh;
  overflow: auto;
}

.float-video-container {
  position: fixed;
  right: 10px;
  bottom: 100px;
  min-width: 600px;
  width: 600px;
  height: 450px;
  margin: 0 auto 100px;
  background-color: #fff;
  box-shadow: -2px 9px 10px 0px #ccc;
  z-index: 5000;
  .float-video-container_close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .detection-evt-video-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    max-width: calc(600px - 30px);
    width: 100%;
    .cam-container {
      height: 360px;
    }
  }
}
</style>
