<template>
  <div class="meta-filters" style="min-height: 200px">
    <table class="table">
      <tr>
        <th>{{ $t("grid.timeline.objectsModal.field5.label") }}</th>
        <td>
          <v-select
            :options="objectTypes"
            v-model="objectType"
            :reduce="(val) => val.value"
            :placeholder="$t('grid.timeline.objectsModal.field5.label')"
            class="w-100"
            @option:selected="onObjectTypeSelected"
          />
        </td>
      </tr>

      <template v-if="objectType && selectedObjectType">
        <tr>
          <th>{{ selectedObjectType.label }}</th>
          <td>
            <div class="d-flex d-flex flex-wrap ot-container">
              <div
                v-for="(attr, otIndex) in selectedObjectType.attrs"
                :key="otIndex"
                class="w-100 pr-1"
              >
                {{ attr.label }}
                <custom-field
                  :key="`${objectType}_${attr.label}`"
                  :type="attr.value"
                  :config="attr.config"
                  :ext-filters="extFilters"
                  v-model="objectTypeValues[objectType][attr.value]"
                  @input="onInput"
                />
              </div>
            </div>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onMounted, computed, nextTick, watch } from "@vue/composition-api";
import axios from "@axios";
import CustomField from "./CustomField.vue";

export default {
  components: {
    BSpinner,
    vSelect,
    CustomField,
  },
  props: {
    value: Object,
    extFilters: {
      // This prop expects filters in this way:
      // const filters = {}
      // filters['cameras[]'] = [1, 2, 3]
      // < ... ext-filters="filters" ... />
      type: Object,
      default: () => ({})
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    onMounted(() => loadCatalog());

    const catalog = ref();
    const detectionOptions = ref([]);
    const objectTypes = ref([]);
    const objectType = ref();
    const objectTypeValues = ref({ type: null, data: null, valid: true });

    const selectedObjectType = computed(() => {
      if (objectTypes.value.length === 0 && !objectType.value) return null;

      const selectedOT = objectTypes.value.filter(
        (ot) => ot.value === objectType.value
      )[0];

      return selectedOT;
    });

    watch(selectedObjectType, (val) => {
      if (!val) emit("input", { type: null, data: null, valid: true });
    });

    watch(
      () => props.value,
      (val) => {
        if (!val) {
          reset();
          emit("input", { type: null, data: null, valid: true });
        }
      }
    );

    const reset = () => {
      objectType.value = null;
      objectTypeValues.value = { type: null, data: null, valid: true };
    };

    const loadCatalog = async () => {
      if (!catalog.value) {
        const { data } = await axios.get(
          "v1/services/detection-events/get-detections-catalog"
        );
        catalog.value = data.data;
      }

      buildSelects(catalog.value);

      if (props.value && Object.keys(props.value).length && props.value.type) {
        nextTick(() => {
          objectType.value = props.value.type;
          const typeData = props.value.data[props.value.type];
          objectTypeValues.value[objectType.value] = typeData;
        });
      }
    };

    const onObjectTypeSelected = (val) => {
      objectTypeValues.value = {};
      objectTypeValues.value[objectType.value] = {};

      const OT = objectTypes.value.filter((ot) => ot.value === objectType.value)[0];
      if (OT) {
        OT.attrs.forEach((attr) => {
          objectTypeValues.value[objectType.value][attr.value] = null;
        });
      }
    };

    const buildSelects = (data) => {
      addDetections(data.detections_list);
      buildObjectTypes(data.object_types);
    };

    const addDetections = (detections) => {
      const _detections = detections.map((detection) => ({
        label: detection.label,
        value: detection.value,
      }));
      detectionOptions.value.push(..._detections);
    };

    const buildObjectTypes = (objTypes) => (objectTypes.value = objTypes);
    const onInput = () =>
      emit("input", {
        type: objectType.value,
        data: objectTypeValues.value,
        valid: true,
      });

    return {
      detectionOptions,
      objectTypes,
      objectType,
      selectedObjectType,
      objectTypeValues,

      onInput,
      onObjectTypeSelected,
    };
  },
};
</script>
