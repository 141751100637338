<template>
  <div>
    <div class="text-center">
      <img
        :src="detectionEvent.media.url"
        class="img-fluid w-100"
        @click="openImageModal(detectionEvent.media.url)" 
        v-b-modal.image_modal 
      />
      <img
        :src="detectionEvent.media_plate.url"
        class="img-fluid"
      />
    </div>
    <table class="table table-sm">
      <tr>
        <th>{{ $t("grid.slots.info.detection_events.labels.plate") }}</th>
        <td>{{ detectionEvent.metadata[0].data.plate }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.detection_events.default.title") }}</th>
        <td>{{ detectionEvent.formatted_event_type }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.detection_events.labels.camera_name") }}</th>
        <td>{{ detectionEvent.camera_proxy.camera.name }}</td>
      </tr>
      <tr>
        <th>{{ 'Date' }}</th>
        <td>{{ detectionEvent.created_at }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.detection_events.labels.brand") }}</th>
        <td>{{ detectionEvent.metadata[0].data.brand }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.detection_events.labels.model") }}</th>
        <td>{{ detectionEvent.metadata[0].data.model }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.detection_events.labels.color") }}</th>
        <td>{{ detectionEvent.metadata[0].data.color }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import {} from "bootstrap-vue";
import store from "@/store";

export default {
  components: {
  },
  props: {
    detectionEvent: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async openImageModal(imageUrl){
      await store.dispatch("image_modal/imageUrl", imageUrl );
    },
  }
};
</script>