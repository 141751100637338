export const formatFilters = (objectData) => {
  if (!objectData) {
    throw new Error('MetaFiltersComponent:formatFilters [objectData] param is not valid');
  }

  const filters = {};
  const properties = objectData.data?.[objectData.type];

  if (properties) {
    filters['types[]'] = objectData.type;

    for (const property in properties) {
      if (properties.hasOwnProperty(property)) {
        const object = properties[property];

        if (!object || object.value === null) continue;

        let value;

        if (Array.isArray(object.value)) {
          value = object.value.map(v => v.value).join();
        } else if (typeof object.value === 'object' && 'value' in object.value) {
          value = object.value.value;
        } else {
          value = object.value;
        }

        filters[`data[${property}]`] = `${object.operator}|${value}`;
      }
    }
  }

  return Object.keys(filters).length >= 2 ? filters : {};
}

export const toQueryString = (filters) => {
  let queryString = '';

  for (const key in filters) {
    const value = filters[key];
    if (Array.isArray(value)) {
      for (const v of value) {
        queryString += `${key}=${encodeURIComponent(v)}&`;
      }
    } else {
      queryString += `${key}=${encodeURIComponent(value)}&`;
    }
  }

  return queryString.slice(0, -1);
}
